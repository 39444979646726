.button {
  @apply text-lg;
  @apply text-white;
  @apply pr-8;
  @apply pl-8;
  @apply pt-3;
  @apply pb-3;
  @apply transition-colors;
  @apply inline-block;
  @apply cursor-pointer;
  @apply bg-primary;
  @apply font-serif;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  border-radius: 2px;
  font-weight: 600;
  letter-spacing: 0.8px;
  width: 100%;
  text-decoration: none;
  fill: white;
  text-align: center;

/* These are technically the same, but use both */
/*   overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all; */
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  /* word-break: break-all; */
  /* Instead use this non-standard one: */
  /* word-break: break-word; */
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
/*   -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto; */

  .loading {
    margin-left: auto;
    margin-right: auto;
  }

}


.buttonGroup {
  .button+.button {
    @apply mt-6;
  }
}

.button.icon .button__label {
  display: flex;
  align-items: center;
}

.button.hollow {
  background: none;
  border-style: solid;
  border-width: 2px;
  @apply pt-2.5;
  @apply pb-2.5;
  @apply pl-7;
  @apply pr-7;

}

@screen sm {

  .button {
    width: auto;
    text-align: auto;
  }

  .replaceButton--learn-more {
    .button__label {
      visibility: visible;
      font-size: initial;

      &::after {
        content: '';
      }
    }
  }

  .buttonGroup {
    .button+.button {
      margin-top: 0;
      @apply ml-6;
    }
  }
}

@screen lg {
  .button.grow {
    font-size: 1rem;
    font-size: calc(1.25rem + 0.25vw);
  }
}

.replaceButton--learn-more {
  .button__label {
      visibility: hidden;
      font-size: 0;

    &::after {
      font-size: initial;
      content: 'Learn more';
      visibility: visible;
    }
  }
}


.button.small {
  @apply pr-4;
  @apply pl-4;
  @apply pt-2;
  @apply pb-2;
  @apply text-xs;
}
