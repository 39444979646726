.modal {
  top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  padding: 30px 20px;
  max-width: 600px;
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: default;

  iframe {
    width: 100%;
  }
}

.modal-overlay {
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  overflow-y: auto;
  backdrop-filter: blur(15px);
  display: flex;
  cursor: pointer;
}

.modal__close {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 20px;
}

html.modal-open.has-scroll {
  overflow-y: scroll;
  width: 100%;
}

html.modal-open {
  position: fixed;
  overflow: hidden;
}

.has-scroll {
}

.modal-open body {
  /* overflow-y: hidden; */
}

/* Add to basket modal */
.basketAddModal {
}

.basketAddModal__details {
  background: #f1f1f1;
  border-top: solid 1px #dfdfdf;
  border-bottom: solid 1px #dfdfdf;
  padding: 20px;
}

.basketAddModal__thumb {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background: #bdbdbd;
}

.basketAddModal__crossSell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  text-align: center;
}

.basketAddModal__crossSell-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 33.333%;
  /* margin-top: 20px; */
  /* padding-top: 20px; */
  /* border-top: solid 1px #ddd; */

  &:first-child {
    margin-top: 0;
    border-top: 0;
    padding-top: 0;
  }
}

.basketAddModal__title {
  display: inline-block;
}

.basketAddModal__qty {
  color: #34711e;
  font-size: 18px;
  line-height: 1.5;
}

.basketAddModal__actions {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basketModal__addProductButton {
  font-weight: bold;
  text-decoration: underline;
  fill: #555;
}

.basketModal__addProductButton--success {
  color: green;
}

.basketModal__addProductButton--error {
  color: #b21616;
}

.basketModal__addProductButton:disabled {
  text-decoration: none;
  cursor: default;
}

@screen sm {
  .modal {
    padding: 30px 40px;
    max-width: 600px;
    top: 10%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5%;
  }

  .modal--lg {
    padding: 50px 60px;
    max-width: 1000px;
  }

  .basketAddModal__actions {
    padding: 40px;
  }
}

.fixedModalButtons {
  z-index: 1000;
  left: 20px;
  right: 20px;
  bottom: 20px;
  position: fixed;
}

@screen md {
  .modal--lg {
    padding: 60px 100px;
  }
}

@screen lg {
  .basketAddModal__details {
    padding: 40px;
  }

  .basketAddModal__crossSell {
    padding: 30px 40px 20px;
  }

  .basketAddModal__crossSell-item {
    margin-top: 0;
    margin-top: 0;
    border-top: 0;
    padding-top: 0;
  }
}
