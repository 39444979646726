.slideout-open {
  overflow-y: hidden;
}


#searchPanel {

  &.slideout-menu {
    width: 320px;
  }

  &.slideout-menu-right {
    right: -400px;
  }
}

#menu {
  &.slideout-menu {
    width: 320px;
  }

  &.slideout-menu-right {
    right: -400px;
  }
}

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  @apply bg-white;
  transition: opacity .2s, visibility 0ms, transform .5s cubic-bezier(.23,1,.32,1);
}

.slideout-panel {
  position:relative;
  width:100%;
  -webkit-overflow-scrolling: touch;
}

.slideout-open .slideout-menu {
  display: block;
  overflow-x: hidden;
}

.panel:before {
  content: '';
  display: block;
  background-color: rgba(0,0,0,0.7);
  transition: background-color 0.5s ease-in-out;
}

.menu-open:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.7);
  backdrop-filter: blur(15px);
  z-index: 99;
}

.slideout-menu-panels {
  overflow: hidden;
}

.slideout-menu-panel {
  @apply absolute bottom-0 top-0 w-full bg-white left-0 right-0 p-6;
  transform: translateX(0);
  transition: transform .5s;

  &.is-active {
    visibility: visible;
    transition: transform .25s;
    left: 0;
  }
}

.slideout-pre-hide-right {
  transition: transform .25s;
  transform: translateX(320px)
}

.slideout-pre-hide-left {
  transition: transform .25s;
  transform: translateX(-320px);
}




.slideout-menu-item {

  &.parent-item {

  }

  &.anchor-item {

  }
}

.slideout-menu-link {
  @apply py-2 block outline-none text-lg;
}

button.slideout-menu-link {
  @apply flex items-center justify-center w-full text-left outline-none;

  &:focus,
  &:active {
    @apply outline-none;
  }
}

.slideout-menu-panel-header {
  @apply py-3 block outline-none text-xl flex;

  &:focus,
  &:active {
    @apply outline-none;
  }
}

.slideout-menu-header + .slideout-menu-items {
  @apply mt-4;
}

.slideout-menu-header {
  @apply font-bold text-f3 mt-8
}

.slideout-back-arrow {
  @apply mr-3;
}

@screen md {
  #searchPanel {
    &.slideout-menu {
      width: 400px;
    }

    &.slideout-menu-right {
      right: -400px;
    }
  }
}
