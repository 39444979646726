[x-cloak] {
  display: none !important;
}

/* Gradiants */

.to-transparent {
  --tw-gradient-to: transparent;
}

.via-transparent-60 {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(18, 18, 18, 0.6),
    var(--tw-gradient-to, rgb(18 18 18/0));
}

.from-transparent-80 {
  --tw-gradient-from: rgba(18, 18, 18, 0.8);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(18 18 18/0));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
