.choiceTabs {
  @apply border-primary;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  /* border-radius: 6px; */
  overflow: hidden;
  border-width: 1px;
  border-style: solid;

  label {
    @apply font-headings font-headings text-primary;
    display: block;
    margin-bottom: 5px;
    line-height: 24px;
  }
}

.inputLabel + .choiceTabs {
  margin-top: 10px;
}

.choiceTabs--inline {
  display: inline-flex;

  .choiceTabs__item {
    flex-basis: unset;
  }
}

.choiceTabs__item {
  /* flex-grow: 1; */
  position: relative;
  margin: 0;
  border-width: 1px;
  border-style: solid;
  @apply border-primary;
  flex: 1 1 0px;
  flex-basis: 50%;

/*   &:last-child {
    border-right: 0;
  } */

}

.choiceTabs__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

[type=radio]+label.choiceTabs__tab {
  display: block;
  padding: 18px 15px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;

  z-index: 1;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}


input[type="radio"]:checked + label.choiceTabs__tab {
  @apply bg-primary text-secondary;
}

@screen sm {
  [type=radio]+label.choiceTabs__tab {
    font-size: 20px;
    line-height: 22px;
  }

  .choiceTabs__item {
    flex-basis: unset;
  }
}
