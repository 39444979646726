.loginPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.loginPage__main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loginPage__sidebar {
  position: relative;
  display: flex;
  background: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.loginPage__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loginPage__sidebarImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  overflow: hidden;
}

.loginPage__sidebarContent {
  color: #fff;
  text-align: center;
  padding: 20px;
  z-index: 2;
}

.loginPage__mainContent {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.loginPage__form {
  width: 100%;
}

.loginPage__forgotPassword {
  display: block;
  margin-top: 20px;
  text-decoration: underline;
}

.loginPage__heading {
  font-size: 2.5rem;
  line-height: 1;
  margin-top: 15px;
  font-weight: bold;
}

/* Modal  */

.loginPage__modalHeading {
  @apply text-f4 text-center;
}

.loginPage__modalContent {
}

.loginPage__modalResponse {
}

/* Account Type decision */

.loginPage__accountChoice {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 20px;
  overflow: hidden;
  margin-top: 20px;
}

.accountChoice__option {
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.accountChoice__optionContent {
  padding: 30px;
  text-align: center;
  flex-basis: 100%;
}

.fhaMembership {
  background: #efefef;
  border-radius: 4px;
  display: grid;
  padding: 30px;
  grid-template-columns: auto;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  margin-top: 20px;
  align-items: center;
}

.fhaMembership__copy {
  order: 2;

  p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0;
    @apply font-sans;
  }

  .button {
    margin-top: 20px;
  }
}

.fhaMembership__image {
  order: 1;
}

.fhaMembershipChoice {
  margin-top: 40px;
}

.choiceContent {
  display: none;
}

.choiceContent--show {
  display: block;
}

.accountTypeContent {
  margin-top: 26px;

  p {
    font-size: 16px;
    @apply font-sans;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@screen sm {
  .loginPage {
    flex-direction: row;
  }

  .loginPage--rev {
    .loginPage__main {
      order: 2;
    }

    .loginPage__sidebar {
      order: 1;
    }
  }

  .loginPage__heading {
    font-size: 4.4rem;
    line-height: 1;
    margin-top: 0;
  }

  .loginPage__main {
    height: 100vh;
    min-height: 600px;
    width: 66%;
  }

  .loginPage__mainContent {
    padding: 0;
    max-width: 600px;
    width: 80%;
  }

  .loginPage__sidebar {
    width: 34%;
    height: 100vh;
    min-height: 600px;
  }

  .loginPage__sidebarContent {
    padding: 0;
    max-width: 400px;
    width: 80%;
  }

  .loginPage__form {
    flex-basis: 525px;
  }

  .loginPage__forgotPassword {
    margin-top: 32px;
  }

  .loginPage__modalHeading {
    @apply text-f2 text-center;
  }

  .accountTypeContent {
    margin-top: 40px;

    p {
      font-size: 20px;
      @apply font-sans;
    }
  }

  .fhaMembership {
    grid-template-columns: 2fr 1fr;
  }

  .fhaMembership__copy {
    order: 1;
  }

  .fhaMembership__image {
    order: 2;
  }
}

@screen md {
  .loginPage__accountChoice {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    margin-top: 40px;
  }
}
