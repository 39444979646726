.form-box {
  /* border: 2px solid #4A4A4A; */
  /* padding: 30px 30px 20px; */
  /* width: 70%; */
  /* margin: 0 auto 40px; */

  fieldset {
    border: none;
    padding: 0;
    margin: 0 0 40px;
  }
}

.form-box__heading {
  display: block;
  border-bottom: 2px solid rgba(0, 0, 0, 0.07);
  @apply text-primary font-semibold;
  /* font-family: 'Oswald', sans-serif; */
  /* font-weight: 300;  */

  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 22px;
  /* color: #4A4A4A; */
}

.form-box__fields {
  /* width: 90%; */
  margin: 0 auto;
  display: block;

  input,
  select,
  textarea {
    /* background: #F2F2F2; */
    /* border: none; */
    /* box-shadow: none; */
    /* outline: none; */
  }
}

.form-box__button {
  text-transform: uppercase;
  font-size: 18px;
  width: 100%;
}
