.portal {
}

/* Resource Single page */
.portalResource {
  display: flex;
  margin-bottom: 30px;

  .portalSection__link {
    margin-left: 0;
  }

  .portalSection__actions {
    margin-left: auto;
  }

  .portalFilter {
    /* margin: 30px 50px 0; */
  }
}

.portalFiltersClose {
  cursor: pointer;
  margin-left: auto;
}

.portalResource__sidebar {
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 80;
  background: rgba(225, 225, 225, 0.95);
  backdrop-filter: blur(15px);
  padding: 50px 30px;
}

.portalResource__main {
  width: 100%;
}

/* Section */
.portalSection {
}

.portalSection--pad {
  margin: 80px 0;
}

.portalSection__header {
  display: block;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.portalSection__heading {
  @apply text-f3 text-primary font-bold;
  margin-bottom: 20px;
  text-align: left;
}

.portalSection__actions {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}

.portalSection__link {
  margin-left: 20px;
  width: auto;

  .button__label {
    text-align: left;
  }
}

.portalSection__header + .portalSearch {
  margin-top: 2rem;
}

/* Single Portal Filters  */

.portalFilter {
  margin: 50px 0;
}

.portalFilter__heading {
  @apply text-sm text-gray-500;
}

.portalFilter__content {
  /* margin: 10px 0 0; */
}

/* Type filter */

.portalType {
  .portalIcon {
    font-size: 20px;
  }
}

.portalType__list {
  display: flex;
  flex-wrap: wrap;
}

.portalType__listItem {
  flex-basis: 50%;
  margin-top: 18px;
}

.portalType__label {
  cursor: pointer;
}

.portalType__item {
  background: #f6f6f6;
  @apply text-primary font-semibold text-sm;
  padding: 11px 20px;
  border-radius: 32px;
  margin-right: 18px;
  cursor: pointer;
  display: block;
  text-align: center;
}

.portalType__item--selected {
  @apply bg-primary text-white;
}

.portalType__labelText {
}

.portalType__count {
  display: none;
}

.portalType__checkbox {
  display: none;
}

/* Portal Menu */

.portalMenu {
}

.portalMenu__list {
}

.portalMenu__item {
  padding: 5px 0;
}

.portalMenu__link {
  background: none;
  border-radius: 6px;
  @apply text-primary font-semibold text-sm;
  padding: 10px 15px;
  display: block;

  &:before {
    content: "\f07b";
    font-family: "Font Awesome\ 5 Pro";
    font-weight: bold;
    font-style: normal;
    margin: 0px 10px 0px 0px;
    text-decoration: none;
  }

  &:hover {
    background: #f9f9f9;
  }
}

.portalMenu__item--selected {
  > div {
    > .portalMenu__link {
      @apply bg-primary text-white font-bold;
      &:before {
        content: "\f07c";
      }
    }
  }
}

.portalMenu__subMenu {
  .portalMenu__list {
    padding: 5px 0 5px 20px;
  }

  .portalMenu__link {
    @apply bg-transparent text-gray-700;
  }

  > .portalMenu__list > .portalMenu__item--selected > div > .portalMenu__link {
    @apply bg-transparent text-primary font-bold;
  }
}

.portalMenu__count {
  display: none;
}

/* Tags */
.portalTags {
}

.portalTags__item {
  padding: 5px 0;
}

.portalTags__item--selected {
  .portalTags__checkbox {
    @apply bg-primary;
  }
}

.portalTags__label {
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 10px 15px;

  &:hover {
    background: #f9f9f9;

    .portalTags__checkbox {
      background-color: rgba(65, 66, 71, 0.5);
    }
  }
}

.portalTags__checkbox {
  appearance: none;
  background-color: rgba(65, 66, 71, 0.08);
  border: none;
  border-radius: 2px;
  height: 1rem;
  margin: 0 1rem 0 0;
  position: relative;
  width: 1rem;

  &:after {
    background-color: #fff;
    border-radius: 4px;
    content: "";
    height: 4px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-2px) translateY(-2px);
    width: 4px;
  }
}

.portalTags__labelText {
  @apply text-primary font-semibold;
}

.portalTags__count {
  @apply bg-gray-100 text-primary text-xs;
  align-items: center;
  background-color: rgba(65, 66, 71, 0.08);
  border-radius: 4px;
  display: flex;
  letter-spacing: -1px;
  margin-left: 8px;
  padding: 0 6px;
}

/* Results */
.portalResults {
  margin: 20px 0;
}

.portalResults__list--col {
  display: grid;
  column-gap: 30px;
  row-gap: 30px;
}

.portalResults__list--col-2 {
  grid-template-columns: 1fr;
}

.portalResults__list--col-3 {
  grid-template-columns: 1fr;
}

.portalResults__list--col-4 {
  grid-template-columns: 1fr;
}

.portalResults__list--col-5 {
  grid-template-columns: 1fr;
}

.portalResults__item {
  display: flex;
  position: relative;
}

/* Resource Cards */
.portalCard {
  min-height: 250px;
  border: solid 1px #e1e1e1;
  border-radius: 6px;
  transition: box-shadow 0.25s;
  box-shadow: 0 0 10px 0 rgba(206, 206, 206, 0.4);
  overflow: hidden;
  display: block;
  width: 100%;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0 0 20px 0 rgba(206, 206, 206, 0.6);
  }
}

.portalCard--simple {
  background: #f8f8f8;
  display: flex;
  align-items: center;
  /* justify-content: center;
  text-align: center; */

  .portalCard__content {
    padding: 40px;
    width: 100%;
  }

  .portalCard__chip {
    position: relative;
    color: #005cff;
    top: auto;
    bottom: 0;
    left: auto;
    right: auto;
  }

  .portalCard__title {
    @apply font-bold text-lg text-center mb-0;
  }
}

.portalCard--faq {
  background-image: url("https://img-innoluxe.imgix.net/Portal/FAQ-Background.svg");
}

.portalCard--slim {
  min-height: auto;
}

.portalCard__header {
  min-height: 200px;
  background: #d8d8d8;
  background-position: center;
  background-size: cover;
  position: relative;
}

.portalCard__image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
}

.portalCard__header--tall {
  min-height: 300px;
}

.portalCard__preTitle {
  font-size: 14px;
  @apply font-semibold mb-0;
  color: #005cff;
  margin-bottom: 8px;
}

.portalCard__title {
  @apply text-primary text-base font-serif;
}

.portalCard__content {
  padding: 20px;
}

.portalCard__copy {
  margin-top: 8px;
  @apply text-gray-600;

  :last-child {
    margin-bottom: 0;
  }
}

.portalCard__actions {
  .button {
    margin-right: 10px;
  }

  .button:last-child {
    margin-right: 0;
  }
}

.portalCard__view {
  padding: 5px;
  @apply text-primary;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  background: rgba(246, 246, 246, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portalCard__viewIcon,
.portalCard__viewCopy {
  line-height: 1;
}

.portalCard__chip {
  position: absolute;
  top: 20px;
  right: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  padding: 8px 15px;
  background: rgba(246, 246, 246, 0.8);
  @apply text-primary;
  z-index: 1;

  .portalIcon {
    &:before {
      margin: 0px 10px 0px 0px;
    }
  }
}

.portalIcon {
  font-family: "Font Awesome\ 5 Pro";
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  font-size: 14px;

  &:before {
    margin: 0px 5px 0px 0px;
  }
}

.portalCard__chipText {
  font-size: 12px;
  font-weight: bold;
  text-transform: capitalize;
}

/* resource types */
.portalIcon__faq {
  &:before {
    content: "\f059";
  }
}

.portalIcon__content {
  &:before {
    content: "\f15c";
  }
}

.portalIcon__video {
  &:before {
    content: "\f15c";
  }
}

.portalIcon__download {
  &:before {
    content: "\f302";
  }
}

.portalIcon__pdf {
  &:before {
    content: "\f1c1";
  }
}

.portalIcon__pdf + .portalCard__chipText {
  text-transform: uppercase;
}

.portalIcon__image {
  &:before {
    content: "\f03e";
  }
}

/* Pagination */
.portalPagination {
}

.portalPagination__list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.portalPagination__item {
  @apply font-semibold;
}

.portalPagination__item--selected.portalPagination__item {
  @apply text-primary;
}

.portalPagination__next,
.portalPagination__previous,
.portalPagination__item {
  @apply text-gray-500;
}

.portalPagination__previous {
}

.portalPagination__link {
  display: block;
  padding: 10px;
}

.portalPagination__disabled {
  @apply text-gray-200;
}

/* Breadcrumb */
.portalCrumbs {
  margin: 2rem 0 10px;
}

.portalCrumbs__list {
  display: flex;
}

.portalCrumbs__item {
  margin-right: 10px;
  @apply text-primary;
}

.portalCrumbs__item--selected {
  font-weight: bold;
  @apply text-gray-700;
}

.portalCrumbs__sep {
  margin-right: 10px;
  @apply text-gray-300;
  display: inline-block;

  svg {
    display: inline-block;
  }
}

/* Search */

.portalSearch {
}

.portalSearch__form {
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(159, 159, 159, 0.5);
  border-radius: 6px;
  width: 100%;
  border: none;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 25px;
  padding: 20px 30px;
}

.portalSearch__input {
  display: block;
  width: 100%;
  grid-column: 2;
  grid-row: 1;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #72819c;
  }
}

.portalSearch__submit {
  grid-column: 1;
  grid-row: 1;
  font-size: 30px;
  text-align: left;
  @apply text-primary;
  font-weight: normal;

  &:focus {
    outline: none;
  }
}

.portalSearch__reset {
}

.portalSearch__resetIcon {
}

.portalSearch__loadingIndicator {
}

@screen sm {
  .portalSection__heading {
    @apply text-f1;
  }

  .portalResults__list--col-2 {
    grid-template-columns: 1fr 1fr;
  }

  .portalResults__list--col-4 {
    grid-template-columns: 1fr 1fr;
  }

  .portalResults__list--col-5 {
    grid-template-columns: 1fr 1fr;
  }
}

@screen md {
  .portalResource__sidebar {
    z-index: auto;
    background: rgba(225, 225, 225, 1);
    backdrop-filter: none;
  }

  .portalFiltersClose {
    display: none;
  }

  .portalFilter {
    margin: 50px 0;
  }

  /* Resource Single page */
  .portalResource__sidebar {
    flex-basis: 30%;
    margin-right: 60px;
    overflow-y: auto;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    @apply bg-transparent;
    padding: 0;
  }

  .portalResource__main {
    flex-basis: 80%;
  }

  .portalResource {
    margin: 20px 0;
  }

  .portalType__listItem {
    flex-basis: auto;
  }

  .portalResults__list--col-4 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .portalResults__list--col-5 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .portalSection__header + .portalSearch {
    margin-top: 3rem;
  }

  .portalSearch + .portalCrumbs {
    margin-top: 2rem;
  }

  .portalSection__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .portalSection__heading {
    margin-bottom: 0;
  }

  .portalSection__actions {
    display: flex;
    align-items: center;
    flex-grow: 0;
    justify-content: unset;
  }
}

@screen lg {
  .portalResource {
    margin: 40px 0;
  }

  .portalResource__sidebar {
    flex-basis: 25%;
    margin-right: 60px;
    display: block;
  }

  .portalResource__main {
    flex-basis: 80%;
  }

  .portalResults__list--col-4 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .portalResults__list--col-5 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@screen xl {
  .portalResource {
    margin: 80px 0;

    .portalResults__list--col-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .portalResults__list--col-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .portalResults__list--col-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1480px) {
  .portalResource {
    .portalResults__list--col-4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
