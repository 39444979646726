.offsetBlock {
  position: relative;
  /* margin-bottom: 80px; */
  margin: 3.125rem 0 0;

  &:first-child {
    margin-top: 0;
  }

  /* .button {
    @apply bg-primary text-white;
  } */
}

.offsetBlock__heading {
  @apply text-f3 mb-6 text-primary;
}

.offsetBlock__subHeading {
  @apply text-lg text-primary;
}

.offsetBlock__content {
  padding: 2rem;
  z-index: 1;
  position: relative;
  align-self: flex-start;
  flex-grow: 1;
}

.offsetBlock__bg {
  @apply bg-secondary;
  display: block;
  z-index: -1;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.offsetBlock__image {
  background: grey;
  z-index: 2;
  position: relative;
  overflow: hidden;
  display: block;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.offsetBlock--right {
  .offsetBlock__image {
    order: 2;
  }
}

.offsetBlock__body {

  .copyImage__features {
   margin-top: 70px;
  }

  .copyImage__feature {
    margin-top: 30px;
  }

  .copyImage__icon + .copyImage__featureHeading {
    margin-top: 12px;
  }

  .copyImage__featureHeading {
    @apply text-primary;
  }

  .copyImage__featureCopy {
    @apply text-sm mb-0 mt-2.5;
  }

  .buttonGroup {
    margin-top: 40px;
  }

  .rich-text {
    margin-top: 50px;
  }
}

@screen sm {
  .offsetBlock {
    display: flex;
    /* padding: 0 50px calc(80px + 1vw); */
    padding: 0 0 calc(80px + 1vw);
  }

  .offsetBlock__image {
    width: 700px;
    min-width: 40%;
    top: calc(80px + 1vw);
    border-radius: 6px;
  }

  .offsetBlock__content {
    padding: calc(80px + 1vw) calc(80px + 1vw);
  }

  .offsetBlock__bg {
    @apply rounded;
    left: -40%;
  }

  .offsetBlock--right {
    .offsetBlock__bg {
      left: 0;
      right: -40%;
    }
  }
}

@screen lg {
  .offsetBlock__image {
    @apply shadow;
  }
}
