.quote {
  padding: calc(1.25rem + 0.25vw) calc(1.25rem + 0.25vw);
  text-align: center;
}

.quote__copy {
  @apply text-xl;
  font-weight: normal;
}

.quote__details {
  @apply text-xl;
  margin-top: 10px;
  margin-bottom: 0;
}

@screen lg {
  .quote {
    padding: 80px 40px;
  }

  .quote__copy {
    @apply text-f3 ;
  }

  .quote__details {
    margin-top: 20px;
  }
}
