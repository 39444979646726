.basketItem {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: min-content min-content min-content;
  column-gap: 1rem;
  padding: 1.5rem 0;
}

.basketItem + .basketItem {
  border-top: solid 1px #eee;
}

.basketItems__headers {
  display: none;
}

.basketItem__data {
  vertical-align: middle;
  color: #324464;
}

.basketItem__thumb {
  width: 100px;
  grid-row: 1/4;
  grid-column: 1;
}

.basketItem__desc {
  font-weight: bold;
  grid-row: 1;
  grid-column: 2;
  @apply text-primary text-lg;
}

.basketItem__qty {
  grid-row: 3;
  grid-column: 2;
  margin-top: 15px;
}

.basketItem__price {
  grid-row: 2;
  grid-column: 2;
  align-self: flex-start;
}

.basketItem__remove {
  grid-row: 1/3;
  grid-column: 3;
  color: #cd4521;
}

.basketStats {
  flex-direction: column;
}

.basketStats__item {
  display: flex;
  justify-content: space-between;
  @apply text-primary text-lg;
  border-top: solid 1px #eee;
  padding: 10px 0;
}

@screen sm {
  .basketitems {
    column-gap: 1.5rem;
  }
}

@screen md {
  .basketitems {
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: none;
    border-radius: 6px 6px 0 0;
  }

  .basketItems__headers {
    display: table-row;
  }

  .basketItems__header {
    display: table-cell;
    border-top: solid 2px #dadada;
    border-bottom: solid 2px #dadada;
    background: #fcfcfc;
    padding: 15px 26px;
    color: #324464;
  }

  .basketItems__header:first-child {
    border-top-left-radius: 6px;
    border-left: solid 2px #dadada;
  }

  .basketItems__header:last-child {
    border-top-right-radius: 6px;
    border-right: solid 2px #dadada;
  }

  .basketItems__headerProduct,
  .basketItems__headerActions {
    width: 1%;
  }

  .basketItems__headerDesc {
    width: 100%;
  }

  .basketItems__body {
    display: table-row-group;
  }

  .basketItem {
    display: table-row;
  }

  .basketItem + .basketItem {
    border-width: 0;
    border-top: solid 1px #eee;
  }

  .basketItem__data {
    padding: 14px 20px;
    display: table-cell;
    border-bottom: solid 2px #dadada;
  }

  .basketItem__thumb {
    grid-row: auto;
    grid-column: auto;
    border-left: solid 2px #dadada;
    width: auto;
  }

  .basketItem__desc {
    grid-row: auto;
    grid-column: auto;
  }

  .basketItem__qty {
    grid-row: auto;
    grid-column: auto;
    margin-top: 5px;
  }

  .basketItem__price {
    grid-row: auto;
    grid-column: auto;
    margin-top: 0;
    @apply text-lg;
    font-weight: bold;
  }

  .basketItem__remove {
    grid-row: auto;
    grid-column: auto;
    border-right: solid 2px #dadada;
  }

  .basketStats {
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 40px;
  }

  .basketStats__item {
    border-top: 0;
    padding-top: 0;
    padding-bottom: 0;

    &:after {
      @apply bg-gray-300;
      content: "";
      display: block;
      width: 1px;
      margin: 0 30px;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}
