.accordion {
}

.accordion__item {
  display: block;
}

.accordion__tab {
  border-radius: 0;
  border-style: solid;
  border-width: 2px;
  @apply border-primary;
  display: block;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accordion__indicator {
  @apply bg-primary;
  /* border-radius: 100px; */
  height: 60px;
  width: 60px;
  position: relative;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-self: center;

  &:after {
    color: white;
    fill: white;
     content: url("data:image/svg+xml; utf8,<svg fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M496 256C496 389 389 496 256 496S16 389 16 256S123 16 256 16S496 123 496 256Z'/></svg>");
    display: block;
    margin: 0 auto;
    opacity: 0.6;
    width: 50%;
    height: 50%;
  }
}

.accordion__input {
  margin: 0;
}

.accordion__label {
  font-size: 20px;
  @apply font-headings;
  text-decoration: uppercase;
}

.accordion__content {
  display:  none;
  margin-bottom: 30px;
}

.accordion__item--show {
  .accordion__tab {
    @apply border-green-vibrant;
  }

  .accordion__indicator {
    @apply bg-green-vibrant;

    &:after {

      content: url("data:image/svg+xml; utf8,<svg fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M256 16C123 16 16 123 16 256S123 496 256 496S496 389 496 256S389 16 256 16ZM372 212L244 340C238 345 231 348 224 348S210 345 204 340L140 276C129 265 129 247 140 236C151 225 169 225 180 236L224 280L332 172C343 161 361 161 372 172C383 183 383 201 372 212Z'/></svg>");
        opacity: 1;
    }
  }

  .accordion__content {
    display: block;
  }
}

.toggleOption {
  border: solid 1px #d8d8d8;
  border-radius: 6px;
  padding: 20px;
  line-height: 24px;
  font-size: 18px;
  color: #111;
  display: flex;
  align-items:  center;
  margin-bottom: 20px;
  cursor: pointer;
}

.toggleOption__copy {
  &:first-child {
    margin-right: 20px;
  }

  &:last-child {
    margin-left: 20px;
  }
}

.toggleOption > .toggleOption__input {
  margin: 0;
  position: relative;
  visibility: hidden;
  height: 20px;
  width: 18px;

  &:after {
    content: '';
    visibility: visible;
    display: block;
    position: relative;
    background: #324464;
    color: white;
    width: 18px;
    height: 18px;
    border: 2px;
  }

  &:checked {
    &:after {
      color: white;
      content: url("data:image/svg+xml; utf8, <svg class='w-6 h-6' fill='white' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd'></path></svg>");
    }
  }
}
