.oPageHeader {
  margin-top: calc(1.25rem + 0.25vw);
  margin-bottom: 50px;
  min-height: 37.5rem;
  @apply rounded;
  display: flex;
}

.oPageHeader__container {
  display: flex;
  position: relative;
  width: 100%;
  padding: 6% 6% 0;
  flex-wrap: wrap;
}

.oPageHeader__content {
  width: 100%;
  text-align: center;
}

.oPageHeader__imageWrapper {
  position: relative;
  display: block;

  &:after{
    content: '';
    display: block;
    clear: both;
  }
}

.oPageHeader__image {
  display: block;
  @apply rounded;
  box-shadow: 5px 5px 30px 0 rgba(0,0,0,0.08);
  overflow: hidden;
  align-self: flex-end;
}

.oPageHeader__heading {
  @apply font-normal text-f4;
}

.oPageHeader__copy {
  @apply text-primary font-sans ;
  font-size: 1.25rem;
}

@screen sm {

  .oPageHeader {
    margin-bottom: 11.25rem;
  }

  .oPageHeader__content {
    width: 50%;
    align-self: center;
    padding: 0 6% 6% 0;
    text-align: left;
    margin-bottom: 11.25rem;
  }

  .oPageHeader__heading {
    font-size: 4.375rem;
    line-height: 1.2;
  }

  .oPageHeader__imageWrapper {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: -100px;
  }
}
