.acc-table {
  width: 100%;
  border: 2px solid #DADADA;
  border-radius: 6px 6px 0 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 30px;
  table-layout: auto;

  thead, tbody {
    border: none;
  }

   th {
    background: #FCFCFC;
    border-bottom: solid 2px #DADADA;
    border-top: solid 2px #DADADA;
    padding: 15px 26px;
    color: #324464;
    line-height: 20px;
    font-weight: 600;
    text-align: left;

    &:first-child {
      border-top-left-radius: 6px;
      border-left: solid 2px #DADADA;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-right: solid 2px #DADADA;

    }
  }
tr:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-bottom-right-radius: 6px;
      }
    }
  }

  td {
    color: #324464;
    background: #fff;
    border-bottom: solid 2px #DADADA;

    &:first-child {
      border-left: solid 2px #DADADA;
    }

    &:last-child {
      border-right: solid 2px #DADADA;
    }

    line-height: 20px;
    padding: 14px 20px;
  }
}

.acc-table__colapse-col {
  width: 1%;
}

.acc-table__no-wrap {
  white-space: nowrap;
}

.acc-status {
  background: #ECECEC;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1;
  padding: 6px 10px;
  display: inline-flex;
  margin-left: auto;
  align-items: center;
}

.acc-status--green,
.acc-status--active {
  .acc-status__icon {
    color: #39AE4A;
  }
}

.acc-status--red,
.acc-status--inactive,
.acc-status--disabled {
  .acc-status__icon {
    color: red;
  }
}

.acc-status__icon {
  margin-right: 6px;
  font-size: 12px;
}

.acc-status__copy {
  font-weight: 600;
  display: inline-block;
}
