.cardSelector {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(auto, min-content);
  column-gap: 25px;
  row-gap: 25px;
}

.cardSelector__item {
  @apply border-primary;
  background: #fff;
  border-width: 3px;
  border-style: solid;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.cardSelector__content {
}
.cardSelector__options {
  margin-top: auto;
}

.cardSelector__divider {
  @apply border-primary;
  border-top-width: 3px;
}

.cardSelector__bar {
  @apply bg-primary border-primary text-sm;
  color: #ffffff;
  border-top-width: 3px;
  padding: 9px 12px;
  line-height: 1;
}

.cardSelector__indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:before {
    font-size: 18px;
    content: "\f111";
    font-family: "Font Awesome\ 5 Pro";
    font-weight: normal;
    font-style: normal;
  }

  &:after {
    content: "Not Selected";
  }
}

.cardSelector__checkOption {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  @apply px-6 py-3;
}

.cardSelector__checkOption > .cardSelector__checkInput {
  margin: 0;
  position: relative;
  visibility: hidden;
  height: 20px;
  width: 18px;
  font-size: 18px;
  line-height: 1;
  pointer: cursor;

  &:after {
    content: "";
    visibility: visible;
    display: block;
    position: relative;
    color: white;
    width: 18px;
    height: 18px;
    border: 2px solid;
    @apply border-primary;
  }

  &:checked {
    &:after {
      @apply bg-primary;
      color: white;
      content: url("data:image/svg+xml; utf8, <svg class='w-6 h-6' fill='white' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd'></path></svg>");
    }
  }

  &:disabled {
    pointer: normal;
  }
}

.cardSelector__checkCopy {
  font-weight: 700;
  text-align: right;
  &:first-child {
    margin-right: 20px;
  }

  &:last-child {
    margin-left: 20px;
    margin-left: auto;
  }
}

.cardSelector__item--selected {
  @apply border-green-vibrant;

  .cardSelector__bar {
    @apply border-green-vibrant bg-green-vibrant;
  }

  .cardSelector__indicator {
    &:before {
      content: "\f058";
      font-style: solid;
      font-weight: bold;
    }

    &:after {
      content: "Selected";
    }
  }

  .cardSelector__divider {
    @apply border-green-vibrant;
  }
}

@screen sm {
  .cardSelector {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(300px, min-content);
  }
}

@media (min-width: 1440px) {
  .cardSelector {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
