.interviewConversation {

}

.interviewConversation__item {
  margin: 60px 0;
}

.interviewConversation__question {
  font-weight: bold;
  font-size: 20px;
  @apply text-primary;
}

.interviewConversation__answer {
  font-size: 18px;
  @apply text-gray-600;
}

.interviewRapidFire {

}

.interviewRapidFire__item {
  margin: 60px 0;
}

.interviewRapidFire__question {
  font-weight: bold;
}

.interviewRapidFire__answer {

}
