.callout {
  position: relative;
  margin: 0 0 1rem;
  border: 2px solid #EDEFF3;
  border-radius: 0;
  background-color: #FCFCFC;
  color: #333333;
  font-weight: bold;
  text-align: left;
}

.calloutContainer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
}

.hide {
  display: none !important;
}

.callout .copy {
  margin-bottom: 0;
  padding-top: 0;
  padding-right: 25px;
}

.callout>:last-child {
  margin-bottom: 0;
}

.callout.alert {
  background-color: #f56565;
  color: #fff;
  border-color: #f56565;

  .close-button {
    color: #fff;
  }
}

.callout.warning {
  background-color: #fbe6ab;
  border-color: #e8d18f;
}

.callout.success {
  @apply bg-green-vibrant border-green-vibrant;
  color: #fff;

  .close-button {
    color: #fff;
  }
}


.close-button, .close-button.medium {
    right: 16px;
    right: 1rem;
    top: 8px;
    top: .8rem;
    font-size: 2em;
    line-height: 1;

    &:hover {
      opacity: 0.5;
    }
}

.close-button {
    position: absolute;
    color: #8a8a8a;
    cursor: pointer;
}
