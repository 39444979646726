@font-face {
  font-family: "lola";
  font-weight: 400;
  src: url("../../fonts/FSLolaWeb-Regular/FSLolaWeb-Regular.woff2")
      format("woff2"),
    url("../../fonts/FSLolaWeb-Regular/FSLolaWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "lola";
  font-weight: 400;
  font-style: italic;
  src: url("../../fonts/FSLolaWeb-MediumItalic/FSLolaWeb-MediumItalic.woff2")
      format("woff2"),
    url("../../fonts/FSLolaWeb-MediumItalic/FSLolaWeb-MediumItalic.woff")
      format("woff");
}

@font-face {
  font-family: "lola";
  font-weight: 600;
  font-style: normal;
  src: url("../../fonts/FSLolaWeb-Bold/FSLolaWeb-Bold.woff2") format("woff2"),
    url("../../fonts/FSLolaWeb-Bold/FSLolaWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "lola";
  font-weight: 700;
  font-style: normal;
  src: url("../../fonts/FSLolaWeb-ExtraBold/FSLolaWeb-ExtraBold.woff2")
      format("woff2"),
    url("../../fonts/FSLolaWeb-ExtraBold/FSLolaWeb-ExtraBold.woff")
      format("woff");
}

@font-face {
  font-family: "lola";
  font-weight: 700;
  font-style: italic;
  src: url("../../fonts/FSLolaWeb-ExtraBoldItalic/FSLolaWeb-ExtraBoldItalic.woff2")
      format("woff2"),
    url("../../fonts/FSLolaWeb-ExtraBoldItalic/FSLolaWeb-ExtraBoldItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  src: url("../../fonts/font-awesome/font-awesome-regular.woff2")
    format("woff2");
  src: url("../../fonts/font-awesome/font-awesome-regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  src: url("../../fonts/font-awesome/font-awesome-solid.woff2") format("woff2");
  src: url("../../fonts/font-awesome/font-awesome-solid.woff") format("woff");
  font-weight: 700;
}
