.orderItems {
  margin-top: 80px;
}

.orderItem__row {
  display: flex;
  border-bottom: solid 1px #D8D8D8;
  padding-bottom: 35px;
  margin-bottom: 35px;
}

.orderItem__thumb {
  width: 100px;
  border-radius: 4px;
  border: solid 1px #E0E0E0;
  overflow: hidden;
  flex-shrink: 0;
  align-self: flex-start
}

.orderItem__details {
  margin-left: 30px;
  flex-grow: 1;
}

.orderItem__description {
  font-weight: bold;
  color: #000;
  font-size: 20px;
}

.orderItem__qty {
  margin-top: 12px;
  font-size: 18px;
}

.orderItem__price {
  align-self: center;
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.orderTotals {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: max-content min-content;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin-top: 30px;
  justify-content: end;
  font-size: 16px;
  color: #4F4F4F;
  @apply font-sans;

}


.orderTotals__label {
    text-align: right;

    &:after {
      content: ':'
    }
}

.orderTotals__value {
  text-align: left;
}

.orderTotal {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: max-content min-content;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin-top: 20px;
  justify-content: end;
  align-items: center;

  .orderTotals__label {
    font-size: 20px;
  }

  .orderTotals__value {
    font-weight: bold;
    font-size: 24px;
    color: #000;
  }
}

.orderDetails {
  display: flex;
  align-items: center;
  justify-content: center;

}

.orderDetails__item {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  @apply font-headings;

  &:first-child {
    display: flex;
    align-items: center;

    &:after {
      content: '';
      margin-right: 20px;
      margin-left: 20px;
      width: 1px;
      background: #D8D8D8;
      height: 22px;
    }
  }

}

.orderInfo {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-column-gap: 80px;
  grid-row-gap: 50px;
  margin-top: 40px;
  padding-top: 40px;
  border-top: solid 3px #D8D8D8;
  font-size: 18px;
}

.orderInfo__item {
}

.orderInfo__heading {
  font-size: 24px;
}

.orderInfo__content {
  font-size: 18px;
  margin-top: 10px;
  line-height: 24px;
  @apply font-sans;
}


@screen sm {
  .orderInfo {
    grid-template-columns: max-content max-content;
  }
}
