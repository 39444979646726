.product {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: calc(1.25rem + 0.5vw);
  column-gap: calc(1.25rem + 0.5vw);
  /* margin: 5rem 0; */
}

.product__image {
  grid-column: span 4;
  /* min-height: 300px; */
  /* border-radius: 6px; */
  /* @apply bg-secondary; */
}

.product__title {
  @apply text-f3 font-bold text-primary text-center;
}

.product__price {
  @apply text-base mt-2 mb-0 text-primary text-center;
}

.product__content {
  grid-column: span 4;
}

.product__pro-only {
  border-radius: 6px;
  @apply border-secondary bg-gray-100 mt-7 p-7 border-2 border-secondary;
}

.product__details {
  @apply mt-7 border-gray-200 border-t;
}

.product__details-panel {
  @apply border-gray-200 border-b pt-7 pb-7;
}

.product__details-copy {
  p:last-child {
    margin-bottom: 0;
  }
}

.product__details-heading {
  @apply text-primary text-base font-semibold font-serif;
}

.product__details-link {
  @apply border-gray-200 border-b;
}

.product__details-link,
.product__details-label {
  @apply text-primary text-base font-semibold pt-7 pb-7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.product__details-label {
  &:after {
    content: "\F067";
    font-family: "Font Awesome 5 Pro";
  }
}

.accordion__item--show {
  .product__details-label {
    &:after {
      content: "\F068";
      font-family: "Font Awesome 5 Pro";
    }
  }
}

.product__details-accordian {
  @apply border-gray-200 border-b;
}

.product__button {
  @apply border-primary bg-transparent text-primary mt-7;
  padding: 10px 20px;

  &:hover {
    @apply bg-black text-secondary;
    fill: white;
  }
}

.product__form {
  /* @apply mt-5; */
}

.product__quantity {
  @apply mt-7;
}

.quantityToggle {
  border: solid 2px;
  /* border-radius: 100px; */
  @apply border-primary;
  display: inline-flex;
  line-height: 22px;
}

.quantityToggle__option {
  display: block;
}

.quantityToggle__option:focus {
  @apply outline-none;
}

.quantityToggle__minus {
  padding: 0.75rem 6px 0.75rem 1.5625rem;
}

.quantityToggle__plus {
  padding: 0.75rem 1.5625rem 0.75rem 6px;
}

.quantityToggle__count {
  font-weight: bold;
  min-width: 50px;
  padding: 0.75rem 0;
  text-align: center;
}

.quantityToggle--vert {
  flex-direction: column-reverse;
  .quantityToggle__plus,
  .quantityToggle__minus {
    padding-left: 0;
    padding-right: 0;
  }
}

.productPrice {
  margin-bottom: 0;
  margin-top: 15px;
}

.productPrice__cost {
}

.productPrice__packSize {
  @apply ml-4 bg-gray-200 rounded p-1 pl-2 pr-2 text-xs;

  &:before {
    content: "pack of ";
  }
}

@screen sm {
  .product {
    grid-template-columns: repeat(12, 1fr);
    column-gap: calc(1.25rem + 0.5vw);
    row-gap: calc(1.25rem + 0.5vw);
  }

  .product__image {
    grid-column: span 5;
    min-height: 600px;
  }

  .product__content {
    grid-column: span 7;
    padding: 0 calc(1rem + 0.5vw);
  }

  .product__title {
    @apply text-f1 text-left;
  }

  .product__price {
    @apply text-f5 mt-4 text-left;
  }

  .product__quantity {
    @apply mt-7;
  }

  /*   .product__button {
    @apply bg-primary text-white w-auto;
  } */

  .product__form {
    /* @apply mt-7; */
  }
}

@screen lg {
  .product__image {
    grid-column: span 6;
  }

  .product__content {
    grid-column: span 6;
    padding: 0 calc(3.125rem + 0.5vw);
  }
}

@screen xl {
  .product__image {
    grid-column: span 7;
  }

  .product__content {
    grid-column: span 5;
  }
}
