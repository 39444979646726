body {
  @apply font-serif text-gray-700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  line-height: 1;
  width: 20px;
  height: 20px;
}

.loading__icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  animation: spin 2s linear infinite;
  fill: inherit;
}

.main-content {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

  .flyout-overlay {
  transition: background-color 0.3s, backdrop-filter 0.3s;

  &:before {
    content: none;
    transition: background-color 0.3s, backdrop-filter 0.3s;
  }
}

.flyout-overlay--active {
  &:before {
    content: "";
    transition-delay: 0.2s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 60;
  }

  * {
    filter: blur(3px);
  }
}

.vimeo-responsive {
  padding: 100% 0 0 0;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
