.tiled {
  display: grid;
  gap: 40px;
}

.tiled__heading {
  margin-top: 20px;
  @apply text-f4 font-bold;

  & + .tiled__copy {
    margin-top: 10px;
    @apply font-normal;
  }
}

.tiled {
  &.grid-cols-2 {
    .tiled__heading {
      @apply text-xl;
    }
  }
}

.tiled--center {
  text-align: center;

  .tiled__image {
    img {
      margin: 0 auto;
    }
  }

  .tiled__ctaText {
    justify-content: center;
  }
}

.tiled__copy {
  margin-top: 20px;
  margin-bottom: 0;
  @apply font-medium;

  .rich-text {
    font-size: 18px;
  }
}

.tiled__ctaText {
  font-size: 18px;
  margin-top: 15px;
  display: flex;
  align-items: center;

  &:after {
    content: "\f30b";
    font-family: "Font Awesome\ 5 Pro";
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    margin-left: 8px;
  }
}

/* Card */

.tiledCards {
  a.tiled__item {
    transition: box-shadow 0.25s;

    &:hover {
      @apply shadow;
    }
  }
}

/* Gallery */

.gallery {
  display: grid;
  column-gap: 30px;
  row-gap: calc(1.25rem + 0.25vw);
}

@screen md {
  .tiled {
    &.lg\:grid-cols-2,
    &.lg\:grid-cols-3 {
      .tiled__heading {
        @apply text-f5;
      }
    }
  }

  .tiled {
    row-gap: 40px;
    column-gap: 40px;
  }

  .gallery {
    column-gap: 30px;
    row-gap: 30px;
  }
}

@screen lg {
  .tiled__heading {
    margin-top: 20px;
  }

  .md\:grid-cols-2 {
    .tiled__heading {
      @apply text-f3;
    }
  }
}
