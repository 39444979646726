.searchResults__list--col {
  display: grid;
  column-gap: 15px;
  row-gap: 15px;
}

.searchResults__list--col-2 {
  grid-template-columns: 1fr 1fr;
}

.searchResults__item {
  display: flex;
  position: relative;
}

