.brickWall {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: "first second";
  grid-auto-rows: calc(12.5rem + 1vw);
  column-gap: calc(1.25rem + 0.5vw);
  row-gap: calc(1.25rem + 0.5vw);
}

.brickWall__item {
  .button {
    @apply bg-primary text-white;
  }
}

.brickWall__item {
  padding: 8%;
  transition: box-shadow 0.25s;

  &:nth-child(4n),
  &:nth-child(4n + 1) {
    grid-column: span 12;
  }

  &:nth-child(4n + 2),
  &:nth-child(4n + 3) {
    grid-column: span 12;
  }
}

a.brickWall__item {
  &:hover {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  }
}

.brick-pane__heading {
  @apply text-lg;
  @apply font-sans;
  @apply font-semibold;
  @apply text-primary;
}

.brick-pane__copy {
  @apply text-xl;
}

.brick--long {
  @apply text-right items-start;
  @apply justify-end;
}

.brick--short {
  @apply justify-center items-end text-center;
}

@screen sm {
  .brickWall {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "first second";
    grid-auto-rows: calc(25rem + 1vw);
    column-gap: calc(1.5rem + 0.5vw);
    row-gap: calc(1.5rem + 0.5vw);
  }

  .brickWall__item {
    padding: 8%;
    transition: box-shadow 0.25s;

    &:nth-child(4n),
    &:nth-child(4n + 1) {
      grid-column: span 8;
    }

    &:nth-child(4n + 2),
    &:nth-child(4n + 3) {
      grid-column: span 4;

      .brick-pane__heading {
        display: none;
      }
    }
  }

  .brick-pane__heading {
    @apply text-f3;
  }
}

@screen md {
  .brickWall__item {
  }
}

@screen lg {
  .brickWall__item {
    /* min-height: calc(25rem + 1vw); */
  }

  .brick-pane__heading {
    @apply text-f2;
  }

  .brick-pane__copy {
    @apply text-xl;
  }
}
