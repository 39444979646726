.card {
  background: #FFFFFF;
  border: 2px solid #DADADA;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.05);
  border-radius: 6px;
  display: block;
  /* flex-basis: 33.333%; */
  /* flex-grow: 1; */
  /* margin: 25px; */
  /* max-width: 33.333%; */
  overflow: hidden;
}

a.card:hover {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);

}

.card__heading {
  @apply text-primary font-bold;
  font-size: 1.25rem;
}

.card__copy {
  margin-top: 10px;
}

.card__img {
  width: 100%;
}

.card__content {
  padding: 30px;
}

.card__actions {
  margin-top: 20px;
}
