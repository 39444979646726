.copyImage {

}

.copyImage--float {

}

.copyImage--offset {

}

.copyImage__image {

}

.copyImage__content {

}

.copyImage__feature {

}

.copyImage__featureHeading {

}

.copyImage__featureCopy {

}

.copyImage__strapline {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  @apply text-gray-500;
}

.copyImage__heading {
  font-size: 2rem;
  @apply text-primary;
  font-weight: 600;
  margin-bottom: 1.875rem;
}

.copyImage__subheading {
  @apply text-lg text-primary;
  @apply mb-9;
}

.copyImage__copy {

}

@screen sm {
}

@screen md {

  .copyImage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;

    &--right {
      .copyImage__image {
        order: 2;
      }
    }
  }

}

@screen lg {
  .copyImage__heading {
    font-size: 3rem;
    font-size: calc(2.5rem + 0.5vw);
  }

  .copyImage__subheading {
    font-size: 1.25rem;
    font-size: calc(1.125rem + 0.25vw);
  }

  .copyImage__copy {
    font-size: 1.25rem;
    font-size: calc(1.125rem + 0.25vw);
  }
}
