.pane {
  position: relative;
  display: flex;
  overflow: hidden;
  @apply rounded;
  padding: 5%;
}

.pane--slim {
  padding: 40px;

  .pane__heading {
    font-size: 28px;
  }

  .pane__copy {
    font-size: 18px;
    @apply mt-5;
  }
}

.pane--stack-on-small {
  display: block;
  .pane-image {
    position: relative;
  }
}

.pane-image {
  position: absolute;
  overflow: hidden;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  opacity: 0.3;
}

.pane-content {
  z-index: 1;
  position: relative;
}

.pane__heading {
  @apply text-f5;
  @apply text-primary;
  @apply font-semibold;
}

.pane__copy {
  @apply text-lg;
  @apply text-gray-700;
  @apply mt-7;

  :last-child {
    margin-bottom: 0;
  }
}

@screen sm {
  .pane--stack-on-small {
    display: block;

    .pane-image {
      position: absolute;
    }
  }
}

@screen lg {
  .pane-content {
  }

  .pane__heading {
    font-size: 2rem;
    font-size: calc(2rem + 0.5vw);
  }

  .pane__copy {
    font-size: 1.25rem;
    font-size: calc(1.125rem + 0.35vw);
  }

  .pane-image {
    opacity: 1;
  }
}
