/* Sections */

.page-content {
  flex-grow: 1;

  .section:last-child {
    margin-bottom: 3rem;
  }

  .section.section--bg:last-child {
    margin-bottom: 0;
  }
}

.section {
  /* overflow: auto; */
}

.section__cta {
  margin-top: 3rem;
  text-align: center;
}

.section.section--bg {
  overflow: auto;

  .container {
    .panel:last-child,
    .section__cta {
      margin-bottom: 2rem;
    }
  }
}

.section + .section--bg {
  margin-top: 5rem;
}

.section.section--pt-none.section--bg .container {
  .section__title:first-child,
  .panel:first-child {
    margin-top: 0;
  }
}

.section--pt-sm.section--bg .container {
  .panel:first-child {
    margin-top: 1.875rem;
  }
}

.section--pt-md.section--bg .container {
  .section__title:first-child,
  .panel:first-child {
    margin-top: 3.75rem;
  }
}

.section--pt-lg.section--bg .container {
  .panel:first-child {
    margin-top: 6.25rem;
  }
}

.section.section--pb-none.section--bg .container {
  .panel:last-child,
  .section__cta {
    margin-bottom: 0;
  }
}

.section--pb-sm.section--bg .container {
  .panel:last-child,
  .section__cta {
    margin-bottom: 1.875rem;
  }
}

.section--pb-md.section--bg .container {
  .panel:last-child,
  .section__cta {
    margin-bottom: 3.75rem;
  }
}

.section--pb-lg.section--bg .container {
  .panel:last-child,
  .section__cta {
    margin-bottom: 6.25rem;
  }
}

.section--bg.section--mb-sm + .section--bg,
.section--bg.section--mb-md + .section--bg,
.section--bg.section--mb-lg + .section--bg,
.section--bg.section--mb-xl + .section--bg,
.section--bg + .section--bg {
  margin-top: 0;
}

.section--mb-none + .section--bg {
  margin-top: 0;
}

.section--mb-none + .section:not(.section--bg) .container {
  .section__title:first-child,
  .panel:first-child {
    margin-top: 0;
  }
}

.section--mb-sm + .section:not(.section--bg) .container,
.section--mb-md + .section:not(.section--bg) .container,
.section--mb-lg + .section:not(.section--bg) .container,
.section--mb-xl + .section:not(.section--bg) .container {
  .section__title:first-child,
  .panel:first-child {
    margin-top: 3rem;
  }
}

.section--mb-sm + .section--bg,
.section--mb-md + .section--bg,
.section--mb-lg + .section--bg,
.section--mb-xl + .section--bg {
  margin-top: 3rem;
}

.section--inset-none {
  margin-right: calc(1.25rem + 0.25vw);
  margin-left: calc(1.25rem + 0.25vw);
}

.section--inset-sm {
  max-width: 1400px;
  margin-right: calc(1.25rem + 0.25vw);
  margin-left: calc(1.25rem + 0.25vw);
}

.section--inset-md {
  max-width: 1400px;
  margin-right: calc(2.5rem + 0.25vw);
  margin-left: calc(2.5rem + 0.25vw);
}

.section--inset-lg {
  max-width: 1400px;
  margin-right: calc(5rem + 0.25vw);
  margin-left: calc(5rem + 0.25vw);
}

.section--gap {
  margin-right: calc(1.25rem + 0.25vw);
  margin-left: calc(1.25rem + 0.25vw);
}

.section__title {
  margin-top: 2rem;
  @apply text-f5 font-medium text-center text-primary;

  + .panel {
    margin-top: 1.5rem;
  }
}

/* Container */
.container {
  max-width: 100rem;
  /* max-width: 87.5rem; */
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(1.25rem + 0.25vw);
  padding-right: calc(1.25rem + 0.25vw);
}

.container--flush {
  padding-left: 0;
  padding-right: 0;
}

.container--sm {
  max-width: 40rem;
}

.container--md {
  max-width: 48rem;
}

.container--lg {
  max-width: 73.75rem;
}

.container--xl {
  max-width: 100rem;
}

.container--fw {
  max-width: none;
}

/* Panel */
.panel {
  margin-top: 3rem;
}

.panel__heading {
  @apply text-f5 text-center text-primary;

  & + .panel__content {
    @apply mt-6;
  }
}

.panel--gap-none,
.section__title--gap-none {
  + .panel {
    margin-top: 0;
  }
}

.panel--gap-sm,
.section__title--gap-sm {
  + .panel {
    margin-top: 1.875rem;
  }
}

.panel--gap-md,
.section__title--gap-md {
  + .panel {
    margin-top: 3.75rem;
  }
}

.panel--gap-lg,
.section__title--gap-lg {
  + .panel {
    margin-top: 6.25rem;
  }
}

/* –––––––––––––––––––––––––––––––––– */

/* Video Embed */

.videoEmbed {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* .panel--mt-none {
  margin-top: 0;
}

.panel--mt {
  margin-top: 5rem;
}

.panel--mb-none {
  margin-bottom: 0;

  & + .panel {
    margin-top: 0;
  }
} */

/* Utilities */

@screen sm {
  .section__title {
    @apply text-f2;
  }

  .panel__heading {
    @apply text-f2;

    & + .panel__content {
      @apply mt-6;
    }
  }

  .panel {
    margin-top: 5rem;
  }
}

@screen md {
}

@screen lg {
  /* Panel */
  .panel {
    margin-top: 5rem;
  }

  .section.section--bg {
    .container {
      .panel:last-child,
      .section__cta {
        margin-bottom: 5rem;
      }
    }
  }

  .section__cta {
    margin-top: 3rem;
  }

  .section__title {
    margin-top: 5rem;

    + .panel {
      margin-top: 3rem;
    }
  }

  .page-content {
    .section:last-child {
      margin-bottom: 5rem;
    }
  }

  .section--mb-sm + .section--bg {
    margin-top: 1.875rem;
  }

  .section--mb-sm + .section:not(.section--bg) .container {
    .section__title:first-child,
    .panel:first-child {
      margin-top: 1.875rem;
    }
  }

  .section--mb-sm {
    .circleBlock:last-child {
      margin-bottom: 1.875rem;
    }
  }

  .section--mb-md + .section--bg {
    margin-top: 3.75rem;
  }

  .section--mb-md {
    .circleBlock:last-child {
      margin-bottom: 3.75rem;
    }
  }

  .section--mb-md + .section:not(.section--bg) .container {
    .section__title:first-child,
    .panel:first-child {
      margin-top: 3.75rem;
    }
  }

  .section--mb-lg + .section:not(.section--bg) .container {
    .section__title:first-child,
    .panel:first-child {
      margin-top: 6.25rem;
    }
  }

  .section--mb-lg + .section--bg {
    margin-top: 6.25rem;
  }

  .section--mb-lg {
    .circleBlock:last-child {
      margin-bottom: 6.25rem;
    }
  }

  .section--mb-xl + .section:not(.section--bg) .container {
    .section__title:first-child,
    .panel:first-child {
      margin-top: 7.5rem;
    }
  }

  .section--mb-xl + .section--bg {
    margin-top: 7.5rem;
  }
  .section--mb-xl {
    .circleBlock:last-child {
      margin-bottom: 7.5rem;
    }
  }
}
