.footer-main {
}

@screen sm {
  .footer-main {
    padding-top: 4.375rem;
  }

  .footer-content__grid {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }
}

.footer__section {
}

/* .stripe-icon {
  content: "\f429";
  font-family: "Font Awesome 5 Pro";
}

.visa-icon {
  content: "\f429";
  font-family: "Font Awesome 5 Pro";
}

.mastercard-icon {
  content: "\f1f1";
  font-family: "Font Awesome 5 Pro";
}

.amex-icon {
  content: "\f1f3";
  font-family: "Font Awesome 5 Pro";
}

.discover-icon {
  content: "\f1f2";
  font-family: "Font Awesome 5 Pro";
}

.dinners-club-icon {
  content: "\f24c";
  font-family: "Font Awesome 5 Pro";
}

.paypal-icon {
  content: "\f1ed";
  font-family: "Font Awesome 5 Pro";
} */
