.selectorCard {
  background: #FFFFFF;
  border-width: 2px;
  border-style: solid;
  @apply border-primary;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.05);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
