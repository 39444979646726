.splitCard {
  @apply  flex overflow-hidden bg-secondary flex-wrap relative;

  .buttonGroup {
    @apply mt-6;
  }
}

.splitCard__content {
  @apply p-5 order-2;
}

.splitCard__heading {
  @apply text-f5 font-bold;
}

.splitCard__strapline {

}

.splitCard__body {
  @apply mt-5;
}

.splitCard__image {
  @apply order-1;
  img {
    position: relative;
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% 87%, 0% 100%);
  }
}

.splitCard__one-third {

}

.splitCard--two-third {

}

.splitCard--half {

}

.splitCard--right {
  .splitCard__image {
    img {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 87%);
    }
  }
}

@screen sm {
  .splitCard__content {
    @apply p-8 ;
  }
}

@screen md {
  .splitCard {
    @apply shadow;
    flex-wrap: nowrap;
    clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
  }

  .splitCard__heading {
    @apply text-f4;
  }

   .splitCard__content {
      flex-basis: 50%;
      transform: skew(0deg, -1.5deg);
      padding: 30px 30px 40px;
    }

  .splitCard__image {
    flex-basis: 50%;
    img {
      clip-path: unset;
    }
  }

  .splitCard--right {
    clip-path: polygon(0 0, 100% 3%, 100% 100%, 0% 97%);

    .splitCard__content {
      transform: skew(0deg, 1.5deg);
    }

    .splitCard__image {
      @apply order-2;

      img {
        clip-path: unset;
      }
    }
  }
}

@screen lg {
  .splitCard {
    clip-path: polygon(3% 0, 100% 0, 97% 100%, 0% 100%);
    margin-left: 50px;
    min-height: 400px;
  }

  .splitCard__content {
    padding: 3rem 4.5rem 3rem 1rem;
    transform: skew(354deg, 360deg);
    display: flex;
    justify-content: center;
    align-items:center;
  }

   .splitCard__image {
     img {
      clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    }
  }

  .splitCard--right {
    clip-path: polygon(0 0, 97% 0, 100% 100%, 3% 100%);
    margin-right: 50px;
    margin-left: 0;


    .splitCard__image {
      img {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
      }
    }

    .splitCard__content {
     padding: 3rem 1rem 3rem 4.5rem;
      transform: skew(364deg, 360deg);
    }
  }

  .splitCard--one-third {
    .splitCard__image {
      flex-basis: 33.333%;
    }

    .splitCard__content {
      flex-basis: 66.667%;
    }
  }
}

