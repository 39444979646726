.addressSelector {
  display: grid;
  grid-auto-rows: minmax(300px, min-content);
  row-gap: 25px;
}


@screen sm {
  .addressSelector {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(300px, min-content);
    column-gap: 25px;
  }
}


@media (min-width: 1440px) {
  .addressSelector {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
