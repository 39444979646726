.cardGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* grid-auto-rows: calc(18.75rem); */
  column-gap: calc(1.25rem + 0.5vw);
  row-gap: calc(1.25rem + 0.5vw);
}

.cardGrid__card {
  grid-column: span 12;
  text-align: center;
}

.productCard {
  display: block;
  position: relative;

  &:hover {
    .productCard__image {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    }

    .productCard__title {
      @apply text-primary;
    }
  }
}

.productCard__label {
  @apply bg-primary font-semibold;
  border-radius: 12px;
  color: white;
  font-size: 12px;
  line-height: 22px;
  padding: 0 15px;
  text-transform: uppercase;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;
}

.productCard__label--new {
  @apply font-bold;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.productCard__notification {
  display: block;
  color: #b21616;
  font-size: 14px;
  margin-top: 20px;
}

.productCard__image {
  @apply bg-secondary;
  border-radius: 6px;
  display: block;
  transition: box-shadow 0.25s;
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;
}

.productCard__img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
}

.productCard__title {
  @apply font-semibold font-serif text-lg;
  margin-top: 20px;
}

.productCard__price {
  margin-top: 11px;
}

@screen sm {
  .cardGrid {
    column-gap: calc(2rem + 0.5vw);
    row-gap: calc(2rem + 0.5vw);
  }

  .cardGrid__card {
    grid-column: span 6;
  }
}

@screen lg {
  .cardGrid__card {
    grid-column: span 4;
  }
}
