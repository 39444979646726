.pagination {
  display: flex;
  flex-direction: row;
  margin: 3rem 0;
  text-align: center;
}

.pagination__details {
  padding: 30px 0;
}

.pagination__previous,
.pagination__next {
  padding: .5rem;
  width: auto;
}

@screen lg {
  .pagination {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 0 70px;
  }
}
