.optionCard {
  margin-bottom: 20px;
}

.optionCard--small-gap {
  margin-bottom: 10px;
}

.optionCard__label {
  border-radius: 0;
  @apply border-primary;
  border-style: solid;
  border-width: 2px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.optionCard__label--double {
  min-height: 100px;
}

.optionCard__details {
  display: flex;
  align-items: center;
}

.optionCard__input {
  line-height: 24px;
  margin: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.inputItem--error {
  .optionCard__label {
    border-color: #cc4b37;
  }
}

.optionCard__indicator {
  width: 18px;
  height: 18px;
  color: #ccc;
  background: #ccc;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  opacity: 1;

  &:after {
    transform: scale(1);
    opacity: 1;
    width: 6px;
    height: 6px;
    margin-left: -3px;
    margin-top: -3px;
    background-color: #fff;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.optionCard:hover .optionCard__input ~ .optionCard__indicator,
.optionCard__input:focus ~ .optionCard__indicator {
  background: #324464;
  opacity: 0.6;
}

/* Checked state */
.optionCard__input:checked ~ .optionCard__indicator {
  background: #324464;
  opacity: 1;
}

/* Hover state whilst checked */
.optionCard:hover
  .optionCard__input:not([disabled]):checked
  ~ .optionCard__indicator,
.optionCard__input:checked:focus ~ .optionCard__indicator {
  background: #324464;
  opacity: 1;
}

/* Disabled state */
.optionCard__input:disabled ~ .optionCard__indicator {
  pointer-events: none;
  opacity: 0.6;
  background: #e6e6e6;
}

/* Show check mark */
.optionCard__input:checked ~ .optionCard__indicator:after {
  display: block;
}

.optionCard__title {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #111;
}

.optionCard__info {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-left: auto;
}

.optionCard__additonal {
  display: flex;
  margin-top: 15px;
}

.optionCard__copy {
  width: 50%;
  font-size: 14px;
  line-height: 18px;
  margin-left: auto;
  text-align: right;
  color: #324464;
}

.optionCard__postCopy {
  margin-top: 10px;
  font-size: 16px;
  /* line-height: 18px; */
}

.orderSummary__lineItem {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
}

.orderSummary__product-thumb {
  min-width: 65px;
  min-height: 65px;
  width: 65px;
  height: 65px;
  border: solid 1px #cecece;
  position: relative;
  background: #fff;
  border-radius: 6px;
  margin-right: 20px;
  overflow: hidden;
}

.total-line-table {
  width: 100%;
  font-size: 16px;
}

.total-line-table__tbody {
  .total-line__name,
  .total-line__value {
    padding-bottom: 10px;
  }
}

.total-line-table__tfoot {
  .total-line__name,
  .total-line__value {
    position: relative;
    padding-top: 30px;

    &:before {
      content: "";
      position: absolute;
      top: 15px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #e5e5e5;
    }
  }
}

.total-line {
}

.total-line__name {
  text-align: left;
  font-weight: normal;
}

.total-line__value {
  text-align: right;
  font-weight: bold;
  padding-left: 40px;
}

.drawerToggle {
}

/* Payment form */

.payment-form-fields {
  .grid {
  }
}

.discountForm {
  /* display: flex; */

  /*   .input-group-field {
    border: solid 2px #444;
    padding: 10px;
    flex-basis: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;

    &:focus {
      outline: none;
    }
  } */

  .button {
    margin-top: 0;
  }
}

.discountForm__label {
  font-weight: bold;
  color: #111;
}

.discountForm__button {
  background: #444;
  color: #fefefe;
  border-radius: 0;
  margin-top: 0;
  flex-basis: 0;
}

@screen lg {
  .drawerToggle {
    display: none;
  }
}

.drawerToggle__label {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #111;
}

.drawerToggle__action {
  line-height: 18px;
  vertical-align: top;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 20px;
  border: solid 1px #dcdcdc;
  background: #efefefef;
  border-radius: 6px;
}

.drawerToggle__actionIcon {
  color: #4f4f4f;
  font-size: 12px;
  line-height: 18px;
  margin-left: 15px;
}

.orderSummary {
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  height: auto;
}

.orderSummary__inner {
  margin-bottom: 20px;
  /* border-bottom: 1px solid rgb(208, 208, 208); */
}

.orderSummary--transition {
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  overflow: hidden;
}

.orderSummary--is-collapsed {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

@screen lg {
  .orderSummary--is-collapsed {
    height: auto;
    visibility: visible;
    overflow: visible;
  }
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */

.stripe-payment-intents-form {
  width: auto !important;

  .card-holder {
    legend {
      @apply font-headings text-xl uppercase mb-4;
    }

    .grid {
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      margin-bottom: 20px;
    }

    input {
      border: solid 2px #000;
      padding: 14px 18px;
      border-radius: 0;
    }
  }
}

.paymentSource-form .stripe-payment-intents-form {
  width: auto;
}

.StripeElement {
  box-sizing: border-box;
  /* height: 40px; */
  padding: 14px 12px;
  border: 2px solid #000;
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #dedede;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #dedede !important;
}

.card-errors {
  height: 20px;
  padding: 4px 0;
  color: #fa755a;
}

.lock-icon {
  &:after {
    content: "\f30d";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
  }
}
