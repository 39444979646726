h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  @apply font-headings;
}

@screen lg {
  .line-detail {
    &:after {
      content: "";
      display: block;
      width: 100px;
      height: 3px;
      @apply bg-primary;
      @apply mt-4;
    }
  }
}

h1 {
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

p {
  margin-bottom: 1.125rem;
}

a {
  &:hover {
  }
}

ol,
ul,
dl {
}

ul {
}

ol {
}

blockquote {
}

blockquote,
blockquote p {
}

.rich-text {
  color: inherit;

  blockquote {
    margin-bottom: 18px;
    font-size: 20px;
    border-left: solid 3px #ccc;
    padding-left: 18px;
  }

  blockquote p:last-child {
    margin-bottom: 0;
  }

  @apply text-lg;

  p {
    margin-bottom: 18px;
  }

  p.lead {
    font-size: 22px;
    font-weight: 500;
  }

  ul {
    list-style: disc;
    list-style-position: outside;
    padding-left: 16px;

    li {
      margin-top: 14px;

      &:first-child {
        margin-top: 0;
      }
    }

    & + * {
      margin-top: 18px;
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 16px;

    li {
      margin-bottom: 14px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  a:not(.button) {
    color: inherit;
    font-weight: 700;
    text-decoration: underline;
  }

  h1 {
    @apply text-f1;
  }

  h2 {
    @apply text-f5 font-medium;
    margin-top: 3.5rem;

    & + p {
      margin-top: 18px;
    }
  }

  h3 {
    @apply text-f3 font-medium;

    & + p {
      margin-top: 18px;
    }
  }

  h4 {
    @apply text-f4 font-medium;

    & + p {
      margin-top: 18px;
    }
  }

  h5 {
    @apply text-f5 font-medium;

    & + p {
      margin-top: 18px;
    }
  }

  h6 {
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }
}

@screen sm {
  .rich-text {
    h1 {
      @apply text-f1;
    }

    h2 {
      @apply text-f2;
      margin-top: 4rem;

      & + p {
        margin-top: 1.5rem;
      }
    }

    h3 {
      @apply text-f3;
    }

    h4 {
      @apply text-f4;
    }

    h5 {
      @apply text-f5;
    }

    h6 {
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
