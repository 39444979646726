.hero {
  text-align: center;
  @apply p-5;
  padding-top: 10rem;
}

.hero--text-dark {

  .hero-heading {
    @apply text-primary;
  }

  .line-detail {

  }

  .hero-copy {
    @apply text-primary;
  }
}

.hero--text-light {

  .hero-heading {
    @apply text-white;
  }

  .line-detail {
    &:after {
      @apply bg-white;
    }
  }

  .hero-copy {
    @apply text-white;
  }
}

.hero-heading {
  @apply text-f5;
}

.hero-copy {
  @apply text-sm;
  @apply text-gray-700;
}

@screen md {
  .hero {

  }
}

@screen lg {
  .hero {
    min-height: 47.5vw;
    min-height: calc(43.75rem + 3vw);
    padding: 6%;
    text-align: left;
  }

  .hero-copy {
    font-size: 1.25rem;
    /* font-size: calc(1.125rem + 0.35vw); */
    font-size: calc(1rem + 0.25vw);
  }

  .hero-heading {
    font-size: 3rem;
    /* font-size: calc(2.5rem + 1.25vw); */
    font-size: calc(2.5rem + 0.8vw);
  }
}
